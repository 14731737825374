<template>
  <div class="container">
    <h2>ACCESO</h2>
    <input placeholder="Correo" type="text" v-model="correo" autocomplete="off" />
    <input placeholder="Contraseña" @keyup.enter="login" type="password" v-model="password" autocomplete="off" />
    <button @click="login" :disabled="!correoValido(correo) || !passwordValido(password)">INGRESAR</button>
    <br />
    <br />
    <div style="text-align: center" v-if="isWeb">
      Acceder con<br />
      <button class="access-button" @click="googleAuth"><ThemifyIcon icon="google" title="Google" /></button>
    </div>
  </div>
</template>
<script>
import { getAuth, signInWithEmailAndPassword, sendEmailVerification, signOut, signInWithPopup } from 'firebase/auth';
import { GoogleAuthProvider } from 'firebase/auth';
import { Device } from '@capacitor/device';

import ThemifyIcon from 'vue-themify-icons';

export default {
  name: 'Login',
  components: {
    ThemifyIcon,
  },
  data() {
    return {
      isWeb: false,
      correo: '',
      password: '',
    };
  },
  mounted() {
    const este = this;
    (async function getDevice() {
      const info = await Device.getInfo();
      este.isWeb = info.platform === 'web';
    })();

    this.setLoading(false);
  },
  methods: {
    googleAuth() {
      this.providerAuth(new GoogleAuthProvider());
    },
    async providerAuth(provider) {
      this.setLoading(true);
      const auth = getAuth();
      this.notification();
      try {
        await signInWithPopup(auth, provider);
        this.notification('Login Aceptado');
      } catch (error) {
        this.notification(error, provider.providerId + ' Auth Error');
      }
      this.setLoading(false);
    },
    async login() {
      this.setLoading(true);
      const auth = getAuth();
      this.notification();
      try {
        const credentials = await signInWithEmailAndPassword(auth, this.correo, this.password);
        if (!credentials.user.emailVerified) {
          await sendEmailVerification(auth.currentUser);
          this.notification({
            code: 'auth/not-email-verified',
            message: 'Revise su correo (' + this.correo.trim() + '), primero tiene que proceder con la verificación  (OJO que puede estar en la carpeta de SPAM)...',
          });
          await signOut(auth);
          this.setLoading(false);
        } else {
          this.notification('Login Aceptado');
          this.correo = '';
          this.password = '';
          this.setLoading(false);
        }
      } catch (error) {
        await signOut(auth);
        this.setLoading(false);
        this.notification(error, 'Login Error');
      }
    },
  },
};
</script>
